<template>
<div class="container px-6 mx-auto dark:bg-gray-900">
	<loading :active.sync="isLoading"
		:can-cancel="true"
        :on-cancel="onCancel"
        :is-full-page="fullPage">
	</loading>
		<div class="grid grid-cols-3 gap-4">
			<div>
				<h2
					class="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200 text-left"
				>
					Libro Mayor
				</h2>
			</div>
			<div class="py-6 text-base font-semibold uppercase">
				{{objGerenalLeger.accountCode}}
				{{objGerenalLeger.accountName}}
			</div>
			<div class="py-6 text-right">
				<input v-model="numberAccTrans" class="placeholder-gray-500 border-2 py-1 border-gray-300 rounded-l-lg text-sm placeholder-opacity-50 px-1" placeholder="Buscar Numero de Asiento">
				<button type="button" @click="searchAccountEntry" class="px-2 py-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-green-600 border border-transparent rounded-r-lg active:bg-blue-500 hover:bg-green-700 focus:outline-none focus:shadow-outline-purple">
					<i class="icon-search" aria-hidden="true"></i>
				</button>
			</div>
		</div>
		<!-- Card -->
				<form @submit.prevent="getGerenalLeger" autocomplete="off">	
					<div class="grid grid-cols-1 gap-4 items-center p-4 bg-white rounded-lg shadow dark:bg-gray-800" >
							<div class="grid grid-cols-2">
								<div class="text-left">
									Desde: <input type="date" v-model="dateFrom" class="border-2 border-gray-300 rounded">							
									Hasta: <input type="date" v-model="currentDate" class="border-2 border-gray-300 rounded">
								</div>
								
								<div class="text-right">
									<Btn
									title='Buscar'
									/>								
									<div class="text-right">
										Tipo auxiliar: 
										<select v-model="auxiliaryType" @change="getAuxiliary()" class="border-2 border-gray-300 rounded mt-4">
											<option value="contract">Contrato</option>
											<option value="staff">Personal</option>
										</select>
									</div>
								</div>							
							</div>						
							<div class="grid grid-cols-2 gap-1">
								<div class="items-center text-sm w-full">
									<Multiselect 
										v-model="account" 
										tag-placeholder="Add" 
										placeholder="Cuenta" 
										label="name" track-by="code" 
										:options="objAccount" 
										:multiple="false" 
										:taggable="true" 
										@tag="addTag"
									></Multiselect>
								</div>
								<div class="">
									<Multiselect 
										v-model="auxiliary" 
										tag-placeholder="Add" 
										placeholder="Aux" 
										label="name" track-by="code" 
										:options="ObjAuxiliary" 
										:multiple="false" 
										:taggable="true" 
										@tag="addTag"
									></Multiselect>
									<!-- <input type="text" v-model="auxiliary" class="border-2 border-gray-300 rounded" placeholder="Auxiliar"> -->
								</div>
							</div>
							<!--<div class="text-center">
								<span v-if="objGerenalLeger?.previous_amounts !== undefined">
									<span class="d-inline-block" @mouseenter="showPopover = true" @mouseleave="showPopover = false">
									<div class="py-2">SALDO INICIAL:</div><div>{{objGerenalLeger.previous_amounts.cumulative_previous_balance > 0 ? formatNumber(objGerenalLeger.previous_amounts.cumulative_previous_balance, ',',  '$', true) : ''}}</div>
								</span>
									<div class="popover-content" v-if="showPopover" :style="{ top: popoverTop + 'px', left: popoverLeft + 'px'}">
										Débito Anterior: {{ objGerenalLeger.previous_amounts.cumulative_previous_debit > 0 ? formatNumber(objGerenalLeger.previous_amounts.cumulative_previous_debit, ',',  '$', true) : '' }}
										Crédito Anterior: {{ objGerenalLeger.previous_amounts.cumulative_previous_credit > 0 ? formatNumber(objGerenalLeger.previous_amounts.cumulative_previous_credit, ',',  '$', true) : '' }}
									</div>
								</span>
							</div>-->
					</div>
				</form>
					<!-- Card -->					
					<button v-on:click="exportXlsx()" title="Exportar" style="margin-top: 10px" class="px-2 py-2 font-medium leading-5 text-white transition-colors duration-150 bg-green-400 border border-transparent rounded-md active:bg-blue-600 hover:bg-green-500 focus:outline-none focus:shadow-outline-purple">
						<i class="mr-2 icon-arrows-cw" aria-hidden="true"></i> Exportar Excel
					</button>
		<div class="w-full overflow-hidden rounded-lg shadow-xs ">
				<div class="w-full overflow-x-auto">
					<table class="w-full whitespace-no-wrap">
						<thead>
							<tr
								class="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800"
							>
								<th class="px-2 py-3 w-24">N. Asiento</th>
								<th class="px-2 py-3 w-24">Fecha</th>
								<th class="px-2 py-3">Referencia</th>
								<th class="px-2 py-3">Aux</th>
								<th class="px-2 py-3">Descripcion</th>
								<th class="px-2 py-3 w-28">Debito</th>
								<th class="px-2 py-3 w-28">Credito</th>
								<th class="px-2 py-3 w-28">Saldo</th>
							</tr>
						</thead>
						<tbody
							class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800"
						>
						<tr class="text-gray-700 dark:text-gray-400">
								<td class="px-4 text-xs text-left ">
									
								</td>
								<td class="px-4 text-xs text-left">
									
								</td>								
								<td class="px-4 text-sm text-left">
									
								</td>
								<td class="px-4 text-sm text-left">
									
								</td>
								<td class="px-4 text-sm text-left">
									<span v-if="objGerenalLeger?.previous_amounts !== undefined">
									SALDO INICIAL
									</span>
								</td>
								<td class="px-4 text-xs">
									<span v-if="objGerenalLeger?.previous_amounts !== undefined">
									{{objGerenalLeger.previous_amounts.cumulative_previous_debit > 0 ?  formatNumber(objGerenalLeger.previous_amounts.cumulative_previous_debit, ',',  '$', true) : ' ' }}
									</span>
								</td>
								<td class="px-4 text-sm">
									<span v-if="objGerenalLeger?.previous_amounts !== undefined">
									{{objGerenalLeger.previous_amounts.cumulative_previous_credit > 0 ?  formatNumber(objGerenalLeger.previous_amounts.cumulative_previous_credit, ',',  '$', true) : ' '}}
									</span>
								</td>
								<td class="px-4 text-sm">
									<span v-if="objGerenalLeger?.previous_amounts !== undefined">
									{{objGerenalLeger.previous_amounts.cumulative_previous_balance > 0 
									? formatNumber(objGerenalLeger.previous_amounts.cumulative_previous_balance, ',',  '$', true) 
									: `( ${formatNumber(objGerenalLeger.previous_amounts.cumulative_previous_balance, ',',  '$', true)} )`									
									}}
									</span>
								</td>								
							</tr>
							<tr v-for="(item, index) in objGerenalLeger.account_transactions" :key="index" class="text-gray-700 dark:text-gray-400">
								<td class="px-4 text-xs text-left ">
									<router-link :to="{name: 'NumberAccTrans', params: {url: 'entrynumber', number: item.accounting_entry_code}}" class=" border-2 py-1 px-2 border-gray-300 rounded hover:bg-gray-200" >
										{{item.accounting_entry_code}}
									</router-link>
								</td>
								<td class="px-4 text-xs text-left">
									{{item.transactionDate}}
								</td>								
								<td class="px-4 text-sm text-left">
									{{item.reference_type}} &nbsp;&nbsp;{{item.reference}}
								</td>
								<td class="px-4 text-sm text-left">
									{{item.auxiliary}}
								</td>
								<td class="px-4 text-sm text-left">
									{{item.description}}
								</td>
								<td class="px-4 text-xs">
									{{item.debit > 0 ?  formatNumber(item.debit, ',',  '$', true) : ' ' }}
								</td>
								<td class="px-4 text-sm">
									{{item.credit > 0 ?  formatNumber(item.credit, ',',  '$', true) : ' '}}
								</td>
								<td class="px-4 text-sm">
									{{item.progressive_balance  > 0 
									? formatNumber(item.progressive_balance, ',',  '$', true) 
									: `( ${formatNumber(item.progressive_balance, ',',  '$', true)} )`									
									}}
								</td>								
							</tr>
						</tbody>
					</table>
				</div>
				<div class="w-full overflow-x-auto grid grid-cols-2 gap-4">
					<div></div>
					<div class="w-full overflow-x-auto">
						<table  class="w-full whitespace-no-wrap">
							<thead >
								<tr
								class="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800"
								>
									<!--<th class="px-4 py-3">Débito Anterior</th>-->
									<!--<th class="px-4 py-3">Crédito Anterior</th>-->
									<!--<th class="px-4 py-3">Saldo Anterior</th>-->
									<th class="px-4 py-3">Total Débito</th>
									<th class="px-4 py-3">Total Crédito</th>
									<th class="px-4 py-3">Saldo</th>
								</tr>
							</thead>
							<tbody
								class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800"
							>
								<tr v-show="objGerenalLeger.accountCode !==''" class="text-gray-700 dark:text-gray-400">
									<!--<td class="px-4 py-3 text-xs">
										<span v-if="objGerenalLeger?.previous_amounts !== undefined">
										{{objGerenalLeger.previous_amounts.cumulative_previous_debit > 0 ? formatNumber(objGerenalLeger.previous_amounts.cumulative_previous_debit, ',',  '$', true) : ''}}
										</span>
									</td>
									<td class="px-4 py-3 text-xs">
										<span v-if="objGerenalLeger?.previous_amounts !== undefined">
										{{objGerenalLeger.previous_amounts.cumulative_previous_credit > 0 ? formatNumber(objGerenalLeger.previous_amounts.cumulative_previous_credit, ',',  '$', true) : ''}}
										</span>
									</td>
									<td class="px-4 py-3 text-xs">
										<span v-if="objGerenalLeger?.previous_amounts !== undefined">
										{{objGerenalLeger.previous_amounts.cumulative_previous_balance > 0 ? formatNumber(objGerenalLeger.previous_amounts.cumulative_previous_balance, ',',  '$', true) : ''}}
										</span>
									</td>-->
									<td class="px-4 py-3 text-xs">
										{{objGerenalLeger.total_debit > 0 ? formatNumber(objGerenalLeger.total_debit, ',',  '$', true) : ''}}
									</td>
									<td class="px-4 py-3 text-xs">
										{{objGerenalLeger.total_credit > 0 ? formatNumber(objGerenalLeger.total_credit, ',',  '$', true) : ''}}
									</td>
									<td class="px-4 py-3 text-sm">
										<span v-if="objGerenalLeger.total_balance != 0">
											{{
												objGerenalLeger.total_balance > 0 
													? 	formatNumber(objGerenalLeger.total_balance, ',',  '$', true) 
													:	isNaN(objGerenalLeger.total_balance) 
															? 	'' 
															:	`( ${formatNumber(objGerenalLeger.total_balance * -1, ',',  '$', true)} )`
											}}
										</span>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				
			</div>
			<div class="w-full overflow-hidden rounded-lg shadow-xs mt-10">
				<div class="w-full overflow-x-auto">
			<hr />
					<table class="w-full whitespace-no-wrap">
						<thead>
							<tr
								class="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800"
							>
								<th class="px-4 py-3">Historial de transacciones recientes</th>
							</tr>
						</thead>
						<tbody
							class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800"
						>
							<tr v-for="(item, index) in LastobjAccount" :key="index" class="text-gray-700 dark:text-gray-400">
							<!-- <tr  class="text-gray-700 dark:text-gray-400"> -->
								<td class="px-4 py-1 text-xs text-left">
									<button @click="getGerenalLegerClik(item.account.id)"> <i class="icon-right" aria-hidden="true"></i> {{item.account.accountCode }} - {{item.account.accountName}} - {{item.transactionDate}}</button>
									<!-- 1.1.1.2.001 - Banco banesco 18/03/2022 -->
								</td>
							</tr>
						</tbody>
					</table>
				</div>				
			</div>	
	</div>
</template>
<script>
import Banner from '@/components/util/Banner.vue'
import ComboBasic from '@/components/util/ComboBoxBasic.vue'
import FielText from '@/components/util/FielText.vue'
import Btn from '@/components/util/btnBasic.vue'
import Axios from 'axios'
import Multiselect from 'vue-multiselect'
import router from '@/router'
import moment from 'moment'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

// import Swal from 'sweetalert2'
import { mapState } from 'vuex'
	export default {
		name: 'ContentLedgerAccounts',
		components: {
			Banner,
			ComboBasic,
			FielText,
			Btn,
			Loading,
			Multiselect,
			
		},
props: {},
		data() {
			return {
				URL: process.env.VUE_APP_URL_ACC,
				URLAdm: process.env.VUE_APP_URL,
				error: false,
				objGerenalLeger:[],
				objGerenalLegerXlsx:[],
				dateFrom:'2022-01-01',
				dateTo:'',
				currentDate: moment().format('YYYY-MM-DD'),
				account:'',
				numberAccTrans:'',
				auxiliary:null,
				ObjAuxiliary:[],
				objAccount:[],
				LastobjAccount:[],
				auxiliaryType: 'contract',
				showPopover: false,
				popoverTop: 0,
				popoverLeft: 0,
				isLoading: false,
				fullPage: true,
			}
		},
		computed: {
			...mapState(['user','access_token','year']),
		},
		mounted() {
			this.getAccTransaction()
			this.LastTransactions()
			this.getGerenalLegerRouter()
			this.getAuxiliary()
			window.addEventListener('mousemove', this.setPopoverPosition);
			// console.log(this.$route.params.account_id)
		},		
		methods: {
// 			async exportXlsx(){
// 				// Import the js-xlsx library 
// const XLSX = require('xlsx'); 

// // Create an empty workbook 
// const workbook = XLSX.utils.book_new(); 

// // Add a worksheet to the workbook 
// const worksheet = XLSX.utils.aoa_to_sheet([
//   ["Column 1", "Column 2"],
//   [1, 2],
//   [3, 4],
// ]);
// XLSX.utils.book_append_sheet(workbook, worksheet, "My Sheet");

// // Save the workbook as an .XLSX file 
// XLSX.writeFile(workbook, 'my-report.xlsx');
//},
onCancel() {
	console.log('User cancelled the loader.')
},
setPopoverPosition(event) {
    this.popoverTop = event.clientY + 10;
    this.popoverLeft = event.clientX + 10;
},
			async exportXlsx(){
				this.isLoading = true
				setTimeout(() => {
					this.isLoading = false
				}, 2000)
				const URL = `${this.URL}AccountTransactions`
				const fetchConfig = {
                    headers: {
                        Accept : 'application/json',
                        "Content-Type": 'application/json',
                        // Authorization : `Bearer ${this.access_token.replace(/['"]+/g, '')}`	
                    }
				}
				let auxArray = '';
				let codeAux = null;
				if (this?.auxiliary == null) {
					this.auxiliary = ''
				}else {
					auxArray = this.auxiliary.code;
					codeAux = auxArray
				}		
				let params ={}
				if (this.dateFrom == '') {
					params = {
						account_id: this.account.code,
						auxiliary: codeAux,
						company: this.user.companyId,
						year: this.year,
					}
				} else {
					if(this.account != null){
						if (this.account.code===undefined){
							alert('Debe seleccionar una cuenta.')												
							return
						}else{						
							params = {
								fromDate: this.dateFrom,
								toDate: this.currentDate,
								auxiliary: codeAux,
								account_id: this.account.code,
								company: this.user.companyId,
								year: this.year,
							}
						}
					}else{
						alert('Debe seleccionar una cuenta.')												
						return
					}
				}
				try {
					let gerenalLeger = await Axios.post(URL, params, fetchConfig)
					this.objGerenalLegerXlsx = gerenalLeger.data.data
					//console.log('objGerenalLegerXlsx')
					console.log(gerenalLeger)
				} catch (error) {
					Object.keys(error).forEach(key => {					
					})
				}
				
				let options = this.objGerenalLegerXlsx.account_transactions.map(item => {
					let reference_type = item.reference_type ? item.reference_type : '';
					let reference = item.reference ? item.reference : '';
					let N_ASIENTO = item.accounting_entry_code;
					let FECHA = item.transactionDate;
					let REFERENCIA = `${reference_type} ${reference}`;
					let AUX = item.auxiliary ? item.auxiliary : '';
					let DESCRIPCION = item.description;
					let balanceD = 0.00;
					let balanceC = 0.00;
					let SALDO = item.progressive_balance;													
					if(item.accountNature==`D`){
						//D=D-C
						let result = item.debit-item.credit
						if(result>0){
							balanceD = result
						}else{
							balanceC = result*(-1)
						}		
					}else{
						//C=C-D
						let result = item.credit-item.debit
						if(result>0){
							balanceC = result
						}else{
							balanceD = result*(-1)
						}
					}
					if(balanceD<1){balanceD=''}
					if(balanceC<1){balanceC=''}	
					// Create our number formatter.
					const formatter = new Intl.NumberFormat('en-US', {style: 'currency',currency: 'USD',});
					// These options are needed to round to whole numbers if that's what you want.
                    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
                    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)								
					if(balanceD>0){balanceD = formatter.format(balanceD)}
					if(balanceC>0){balanceC = formatter.format(balanceC)}
					balanceD = balanceD.replace(/,/g, "").replace('.', ',').replace('$', '')
					balanceC = balanceC.replace(/,/g, "").replace('.', ',').replace('$', '')
					SALDO = formatter.format(SALDO)
					if(SALDO.includes('-')){
						SALDO = SALDO.replace('-', '')
						//SALDO = "("+SALDO+")"
					}
					SALDO = SALDO.replace(/,/g, "").replace('.',',').replace('$','')
					return { 
						"N_ASIENTO_":N_ASIENTO,"FECHA_":FECHA,"REFERENCIA_":REFERENCIA,"AUX_": AUX, "DESCRIPCION_":DESCRIPCION,"DEBITO_": balanceD,"CREDITO_": balanceC,"SALDO_":SALDO
					}
				})
				const formatter = new Intl.NumberFormat('en-US', {style: 'currency',currency: 'USD',}); 
				let saldo = this.objGerenalLegerXlsx.previous_amounts.cumulative_previous_balance.toString();
				if(saldo.includes('-')){
					saldo = saldo.replace('-', '')
					//saldo = "("+saldo+")"
				}
				// saldo = Number(saldo).toFixed(2)
				// if(saldo<0){
				// 	saldo = Math.abs(saldo)
					
				// }
				saldo = formatter.format(saldo)
				saldo = saldo.replace(/,/g, "").replace('.', ',').replace('$', '')
				let blank = {"N_ASIENTO_":"","FECHA_":"","REFERENCIA_":"","AUX_":"", "DESCRIPCION_":"","DEBITO_":"","CREDITO_":"","SALDO_":""}
				let datosIni = {
					"N_ASIENTO_": "", 
                    "FECHA_": "",
                    "REFERENCIA_": "",
                    "AUX_": "",
					"DESCRIPCION_": "SALDO INICIAL",
                    "DEBITO_": "",
					"CREDITO_": "",
					"SALDO_": saldo
					//"SALDO_": `${formatter.format(this.objGerenalLegerXlsx.previous_amounts.cumulative_previous_balance)}`
				}
				let debit = formatter.format(this.objGerenalLegerXlsx.total_debit)
				let credit = formatter.format(this.objGerenalLegerXlsx.total_credit)
				let saldot = formatter.format(this.objGerenalLegerXlsx.total_balance)
				if(saldot.includes('-')){
					saldot = saldot.replace('-', '')
					//saldot = "("+saldot+")"
				}	
				debit = debit.replace(/,/g, "").replace('.', ',').replace('$', '')
				credit = credit.replace(/,/g, "").replace('.', ',').replace('$', '')
				saldot = saldot.replace(/,/g, "").replace('.', ',').replace('$', '')
				let totales = {
					"N_ASIENTO_": "", 
                    "FECHA_": "",
                    "REFERENCIA_": "",
                    "AUX_": "",
					"DESCRIPCION_": "",
                    "DEBITO_": `TOTAL DÉBITO: ${debit}`,
					"CREDITO_": `TOTAL CRÉDITO: ${credit}`,
					"SALDO_": `SALDO: ${saldot}`
				}
				options.unshift(datosIni);
				options.push(blank);
				options.push(totales);			
				import("./Export2Excel").then((excel) => {
					const OBJ = options;// data json                 
                    const multiheader = [
						["","","",`COMPAÑÍA: ${this.user.companyName}`,"","","",""],
						["","","",`LIBRO MAYOR: ${this.objGerenalLegerXlsx.accountName} ${this.objGerenalLegerXlsx.accountCode}  AUXILIAR: ${this.auxiliary.name ? this.auxiliary.name : "No seleccionado"}`,"","","",""],// header in Excel
						["","","",`Desde: ${this.dateFrom} Hasta: ${this.currentDate}`,"","","",""]
					];
                    const header = ["Nº ASIENTO", "FECHA", "REFERENCIA", "AUX","DESCRIPCIÓN", "DÉBITO", "CRÉDITO", "SALDO"]
					const Field = ["N_ASIENTO_", "FECHA_", "REFERENCIA_", "AUX_","DESCRIPCION_", "DEBITO_", "CREDITO_", "SALDO_"];// Field for map with ob data json
					const Data = this.FormatJSon(Field, OBJ);// data mapped field and obj data 
					excel.export_json_to_excel({
						multiHeader: multiheader,
						data: Data,
						header: header,
						sheetName: moment().format('YYYY-MM-DD'),
						filename: `Libro Mayor_${this.user.companyName}_${this.dateFrom}a${this.currentDate}`,
						autoWidth: true,
						bookType: "xlsx",
                    })
				})
				this.isLoading = false
			},
			FormatJSon(FilterData, JsonData) {// Funtion Format OBJ
				return JsonData.map((v) => FilterData.map((j=>{return v[j];})))
			},
			addTag (newTag) {			
                const tag = {	
                    name: newTag,
                    code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
                }
                this.options.push(tag)
                this.transaction.push(tag)
            },
			formatNumber(number, separator = ',', symbol='$', showSymbol = false){
				number = parseFloat(number)
				number = number.toFixed(2)
				if (separator == ',') {
					let montoNuevo = number.toString().replace(/\D/g, "")
					.replace(/([0-9])([0-9]{2})$/, '$1.$2')
					.replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ",");
					if (showSymbol) {
						return `${symbol}${montoNuevo}`
					} else {
						return `${montoNuevo}`	
					}
				} else {
					let montoNuevo = number.toString().replace(/\D/g, "")
					.replace(/([0-9])([0-9]{2})$/, '$1,$2')
					.replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
					if (showSymbol) {
						return `${symbol}${montoNuevo}`
					} else {
						return `${montoNuevo}`	
					}
				}
			},
			async getAuxiliary() { //
				if (this.auxiliaryType === 'contract') {
					const URL = `${this.URLAdm}v1/contract`
					const fetchConfig = {
						headers: {
							Accept : 'application/json',
							"Content-Type": 'application/json',
							Authorization : `Bearer ${this.access_token.replace(/['"]+/g, '')}`	
						}
					}
					let params = {
						companyId: this.user.companyId
					}
					// try {
					let rsAccount = await Axios.post(URL, params, fetchConfig)
					// return
					const result = rsAccount.data;
					this.ObjAuxiliary = result.map(item => {
						// console.log(item.client.clientName)
							return { name: item.contractNumber + ' - ' + item.siteAddress, code: item.contractNumber}
						// return { text: item.accountName, value: item.id}
					})
				}
				if (this.auxiliaryType === 'staff') {
					const URL = `${this.URLAdm}staff-all`
					const fetchConfig = {
						headers: {
							Accept : 'application/json',
							"Content-Type": 'application/json',
							Authorization : `Bearer ${this.access_token.replace(/['"]+/g, '')}`	
						}
					}
					let rsAccount = await Axios.get(`${URL}/${this.user.companyId}`, fetchConfig)
					// return
					const result = rsAccount.data.staff;
					// return
					this.ObjAuxiliary = result.map(item => {
						// console.log(item.client.clientName)
							return { name: item.staffCode  + ' - ' + item.shortName, code: item.staffCode}
						// return { text: item.accountName, value: item.id}
					})
				}		
			},
			searchAccountEntry(){
				if (this.numberAccTrans === '') {
					alert('Debe ingresar un numero de asiento')
					return
				}
				router.push({ name: 'NumberAccTrans',  params: {url: 'entrynumber', number: this.numberAccTrans}})				
			},
			async getGerenalLeger() {
				this.isLoading = true
					setTimeout(() => {
						this.isLoading = false
					}, 2000)
				const URL = `${this.URL}AccountTransactions`
				const fetchConfig = {
                    headers: {
                        Accept : 'application/json',
                        "Content-Type": 'application/json',
                        // Authorization : `Bearer ${this.access_token.replace(/['"]+/g, '')}`	
                    }
				}
				let auxArray = '';
				let codeAux = null;
				if (this.auxiliary == null) {
					this.auxiliary = ''
				}else {
					if (typeof(this.auxiliary.code) === 'string') {
						auxArray = this.auxiliary.code.split("%");
						codeAux = auxArray[0]
					}
				}					
				let params ={}
				if (this.dateFrom == '') {
					params = {
						account_id: this.account.code,
						auxiliary: codeAux,
						company: this.user.companyId,
						year: this.year,
					}
				} else {
					if(this.account != null){
						if (this.account.code===undefined){
							alert('Debe seleccionar una cuenta.')												
							return
						}else{						
							params = {
								fromDate: this.dateFrom,
								toDate: this.currentDate,
								auxiliary: codeAux,
								account_id: this.account.code,
								company: this.user.companyId,
								year: this.year,
							}
						}
					}else{
						alert('Debe seleccionar una cuenta.')												
						return
					}			
				}			
				try {
					let gerenalLeger = await Axios.post(URL, params, fetchConfig)
					this.objGerenalLeger = gerenalLeger.data.data
					console.log('this.objGerenalLeger')
					console.log(this.objGerenalLeger)					
					//this.totalBalanceReal = this.objGerenalLeger.previous_amounts.cumulative_previous_balance+this.objGerenalLeger.total_balance;					
				} catch (error) {
					Object.keys(error).forEach(key => {
					})
					this.isLoading = false
				}
						
			},
			async getGerenalLegerClik(code) {
				const URL = `${this.URL}AccountTransactions`
				const fetchConfig = {
                    headers: {
                        Accept : 'application/json',
                        "Content-Type": 'application/json',
                        // Authorization : `Bearer ${this.access_token.replace(/['"]+/g, '')}`	
                    }
				}
				let params = {
					account_id: code,
					company: this.user.companyId,
					year: this.year,
				} 
				try {
					let gerenalLeger = await Axios.post(URL, params, fetchConfig)
					this.objGerenalLeger = gerenalLeger.data.data
				} catch (error) {
					Object.keys(error).forEach(key => {
					})
				}			
			},
			async getGerenalLegerRouter() {
				console.log(this.$route.params)
				if (this.$route.params.account_id === undefined) {
					return
				}
				const URL = `${this.URL}AccountTransactions`
				const fetchConfig = {
                    headers: {
                        Accept : 'application/json',
                        "Content-Type": 'application/json',
                        // Authorization : `Bearer ${this.access_token.replace(/['"]+/g, '')}`	
                    }
				}
				let params = {
					account_id: this.$route.params.account_id,
					company: this.user.companyId,
					year: this.year,
				}
				try {
					let gerenalLeger = await Axios.post(URL, params, fetchConfig)
					this.objGerenalLeger = gerenalLeger.data.data
					console.log(this.objGerenalLeger)
				} catch (error) {
					Object.keys(error).forEach(key => {
						// console.log(error[key])
					})
				}
				
			},
			async getAccTransaction() {
				const URL = `${this.URL}showAllCompanyAccountsFlatMode`
				const fetchConfig = {
                    headers: {
                        Accept : 'application/json',
                        "Content-Type": 'application/json'
                        // Authorization : `Bearer ${this.access_token.replace(/['"]+/g, '')}`	
                    }
				}
				let params = {
					company_id: this.user.companyId,
					company: this.user.companyId,
					year: this.year,
				}				
				// try {
					let rsAccount = await Axios.post(URL, params, fetchConfig)
					this.objAccount = rsAccount.data.data.map(item => {
						// return { text: item.accountName, value: item.id}
						return { name: item.accountCode + ' - ' + item.accountName, code: item.id}
					})		
			},
			async LastTransactions() {
				const URL = `${this.URL}lastTransactions`
				const fetchConfig = {
                    headers: {
                        Accept : 'application/json',
                        "Content-Type": 'application/json'
                        // Authorization : `Bearer ${this.access_token.replace(/['"]+/g, '')}`	
                    }
				}
				let params = {
					company_id : this.user.companyId,
					company: this.user.companyId,
					year: this.year,
					quantity : 15,
				}
				// try {
					let rsAccount = await Axios.post(URL, params, fetchConfig)
					this.LastobjAccount = rsAccount.data.data
					// console.log('last: ')				
			},
		},		
		watch: {
		},			
	}
</script>
<style >
.popover-content {
	position: absolute;
	background-color: #ffffff;
	border: 1px solid #cccccc;
	border-radius: 0.25rem;
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
	padding: 0.5rem 1rem;
	font-size: 0.875rem;
	z-index: 1;
	}
	</style>